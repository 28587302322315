<template>
  <div class="forget">
    <!-- <nav-menu></nav-menu> -->
    <el-row
      class="content"
      :gutter="24">
      <el-col
        :xs="0"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="forget-left">
        <TransitionGroup
          appear
          tag="div"
          enter-active-class="animate__animated animate__bounceInLeft">
          <div
            class="left-bg"
            key="1"></div>
        </TransitionGroup>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="forget-right">
        <Transition
          appear
          enter-active-class="animate__animated animate__bounceInRight">
          <el-form
            ref="forgetForm"
            :model="forgetForm"
            :rules="forgetRules"
            class="forget-form"
            auto-complete="on"
            label-position="top">
            <h2
              class="title"
              @click="handleLink">
              BrickSDK
            </h2>
            <h3>{{ type === 'forget' ? '忘记密码' : '修改密码' }}</h3>

            <el-form-item
              v-if="type === 'forget'"
              label="用户名"
              prop="email">
              <el-input v-model="forgetForm.email"></el-input>
            </el-form-item>
            <el-form-item
              label="旧密码"
              prop="old_passwd"
              v-if="type !== 'forget'">
              <el-input
                v-model.trim="forgetForm.old_passwd"
                show-password></el-input>
            </el-form-item>
            <el-form-item
              label="新密码"
              prop="passwd">
              <el-input
                v-model.trim="forgetForm.passwd"
                show-password></el-input>
            </el-form-item>
            <el-row
              :gutter="24"
              v-if="type === 'forget'">
              <el-col :span="18">
                <el-form-item
                  label="验证码"
                  prop="code">
                  <el-input v-model="forgetForm.code"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="6"
                style="margin-top: 50px">
                <el-button
                  :class="[
                    'btn',
                    sendDisabled && !resendShow ? 'btns-info' : 'btns-default',
                  ]"
                  :disabled="sendDisabled"
                  @click="handleResend">
                  <template v-if="resendShow">发送</template>
                  <template v-else>{{ times }}s</template>
                </el-button>
              </el-col>
            </el-row>

            <el-button
              class="btns-default btn zc-btn"
              @click="handelClick"
              >确定</el-button
            >
          </el-form>
        </Transition>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'animate.css';
import { useUserStore } from '@/store/index';
import { forgetPassword, sendCode, changePasswd } from '@/api/login';
import { isEmail, isPassword } from '@/assets/js/validate';
// import navMenu from '@/components/NavMenu';

let timer = null;
export default {
  name: 'forget',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        this.sendDisabled = true;
        callback(new Error('请输入邮箱'));
      } else if (!isEmail(value)) {
        this.sendDisabled = true;
        callback(new Error('请输入正确格式的邮箱'));
      } else {
        this.sendDisabled = false;
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'));
      } else if (!isPassword(value)) {
        callback(new Error('请输入6-15位由字母或数字组成的密码'));
      } else {
        callback();
      }
    };
    return {
      type: '',
      forgetForm: {
        email: '',
        passwd: '',
        code: '',
        old_passwd: '',
      },
      forgetRules: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        passwd: [
          { required: true, validator: validatePassword, trigger: 'blur' },
        ],
        old_passwd: [
          { required: true, validator: validatePassword, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      times: 60,
      resendShow: true,
      sendDisabled: false,
    };
  },
  components: {
    // navMenu,
  },
  methods: {
    handelClick() {
      this.$refs['forgetForm'].validate((valid) => {
        if (valid) {
          this.type === 'forget' ? this.forget() : this.editPasswd();
        } else {
          return false;
        }
      });
    },
    loginOut() {
      setTimeout(async () => {
        await useUserStore().logout();
        this.$router.push('/login');
      }, 1000);
    },
    forget() {
      forgetPassword(this.forgetForm).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loginOut();
        }
      });
    },
    editPasswd() {
      let params = {
        old_passwd: this.forgetForm.old_passwd,
        new_passwd: this.forgetForm.passwd,
      };
      changePasswd(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loginOut();
        }
      });
    },
    handleResend() {
      this.$refs.forgetForm.validateField('email', (err) => {
        if (!err) {
          this.countDown();
          sendCode({
            email: this.forgetForm.email,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
            }
          });
        }
      });
    },
    countDown() {
      let timecount = 60;
      if (!timer) {
        this.times = timecount;
        this.resendShow = false;
        this.sendDisabled = true;
        timer = setInterval(() => {
          if (this.times > 0 && this.times <= timecount) {
            this.times--;
          } else {
            this.resendShow = true;
            this.sendDisabled = false;
            clearInterval(timer);
            timer = null;
          }
        }, 1000);
      }
    },
    handleLink() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.type = this.$route.query.type;
  },
};
</script>

<style lang="scss" scoped>
.forget {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .top-bg {
    margin-top: 40px;
    width: 100%;
    height: 70px;
    background: url(~@/assets/images/video-bg.jpg) no-repeat;
    background-size: cover;
    display: flex;
    padding: 0 60px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  .content {
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .forget-left {
    padding-left: 0 !important;
    border-right: 1px solid #f3d7ff;
    height: 100%;
    div {
      height: 100%;
    }
  }
  .left-bg {
    background: url(~@/assets/images/services-left.jpg) no-repeat left center;
    background-size: 90%;
    width: 100%;
    height: 100%;
  }
  .forget-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .forget-right::after {
    content: url(~@/assets/images/contact-left.jpg);
    bottom: 0px;
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
    width: 103px;
    height: 464px;
    z-index: -1;
  }
  .forget-form {
    width: 50%;
    h3 {
      margin: 80px 0 20px 0;
    }
    .title {
      color: #c03afe;
      margin-bottom: 40px;
      cursor: pointer;
    }
    .btns-default:hover {
      opacity: 0.8;
    }
    .btn {
      background-color: #5b03e4;
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
    }
    .btns-default {
      background-color: #5b03e4;
      color: #fff;
    }
    .btns-info {
      color: #909399;
      background: #f4f4f5;
      border: 1px solid #dcdfe6;
    }
    .btn:hover {
      opacity: 0.8;
    }
    .zc-btn {
      margin: 30px 0;
    }
    .forget {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #f3d7ff;
      a {
        color: #5b03e4;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
}
</style>
